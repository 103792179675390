import React, { useState } from 'react';
import { GraphicsContainerWithTitle, GraphicsContainer } from './Graphics.css';
import { useStaticQuery, graphql } from 'gatsby';
import LocaleContext from '../../context/LocaleProvider';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HeadingContainer from '../../components/PageSections/HeadingContainer/HeadingContainer';
import SingleChart from './SingleChart';
import IntersectionVisible from 'react-intersection-visible';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  parentGrid: {
    justifyContent: 'space-between',
  },
  pie: {
    alignItems: 'self-start',
    padding: '0px 0px 20px',
    justifyContent: 'space-between',
    maxWidth: 150,
    margin: '0 auto',
    flexBasis: '45%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
      maxWidth: 200,
      padding: '0px 0px 40px',
    },
  },
}));

const Graphics = ({ hideTitle, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];
  const data = useStaticQuery(graphql`
    query graphicsQuery {
      graphics: allPrismicDataGraphics {
        edges {
          node {
            lang
            data {
              title {
                text
              }
              graphic {
                value
                number
                body {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    node: { data: graphicsQuery },
  } = data.graphics.edges.find((edge) => edge.node.lang === i18n.locale);

  const classes = useStyles();

  return (
    <IntersectionVisible
      onShow={() => setIsVisible(true)}
      onHide={() => setIsVisible(false)}
    >
      <GraphicsContainerWithTitle
        className={`${className ? className : ''} Graphics__Container`}
      >
        {graphicsQuery.title && !hideTitle ? (
          <HeadingContainer
            className='Graphics__Title'
            title={graphicsQuery.title.text}
          />
        ) : (
          ''
        )}
        <Fade delay={500} bottom distance='30px'>
          <GraphicsContainer className={classes.root}>
            <Grid className={classes.parentGrid} container>
              {graphicsQuery.graphic.map((graphics, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={6}
                    md={3}
                    className={classes.pie}
                    key={index}
                  >
                    <SingleChart
                      isVisible={isVisible}
                      query={graphics}
                      index={index}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </GraphicsContainer>
        </Fade>
      </GraphicsContainerWithTitle>
    </IntersectionVisible>
  );
};

export default Graphics;
