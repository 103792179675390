import styled from "@emotion/styled";
import { theme } from "../../styles";

export const CountryFlagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom:  60px;
  justify-content: center;
  
  @media (min-width: ${theme.breakpoints.s}) {
    margin-bottom:  80px;
    justify-content: space-around;
  }
  
  @media (min-width: ${theme.breakpoints.m}) {
    margin-bottom:  ${theme.scale('120px')};
    margin-left: -20px;
    width: calc(100% + 40px);
    justify-content: flex-start;
  }

  .CountryFlag__ImageContainer {
    width: 115px;
    margin: 0 0px 20px;
    padding: 7px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    @media (min-width: ${theme.breakpoints.xs}) {
      padding: 10px;
    }

    @media (min-width: ${theme.breakpoints.s}) {
      margin-bottom: 25px;
      width: 170px;
    }


    @media (min-width: ${theme.breakpoints.md}) {
      width: 14.25%;
      max-width: ${theme.scale('195.5px')};
      padding: ${theme.scale('20px')};

      &:hover {

        img {
          transform: scale(0.95);
        }
  
        &::after {
          opacity: 1;
          mix-blend-mode: multiply;
        }
  
        .CountryFlag__Link {
          opacity: 1;
        }

        img {
          filter: grayscale(100%);
        }
      }
    }

    img {
      transition: 300ms;
      transition-property: transform;
      will-change: transform;
      max-width: 95%;
      width: 100%;
    }

    &::after {
      content: '';
      background: ${theme.colors.primaryred};
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: 300ms;
      will-change: opacity;
      z-index: 1;
    }
  }

  .CountryFlag__Link {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    padding: 10px;
    display: flex;
    color: white;
    opacity: 0;
    z-index: 2;
    text-transform: uppercase;
    font-size: ${theme.scale(theme.fonts.m)};
    letter-spacing: .5px;
  }
`;