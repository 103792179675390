import styled from '@emotion/styled';
import { theme } from '../../../styles';

export const ExpertiseSectionContainer = styled.div`
  margin-bottom: 80px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.scale('150px')};
  }

  .Home__Section_Title {
    text-transform: uppercase;
    color: black;
    margin-bottom: 70px;
    font-family: ${theme.fontFamily.primary};

    @media (min-width: ${theme.breakpoints.l}) {
      width: 30%;
    }
  }

  p,
  h3 {
    color: ${theme.colors.primaryblue};
  }

  .Expertise__SliderText-Mobile {
    margin: 20px 0px 40px 0px;
    font-size: ${theme.fonts.m};

    @media (min-width: ${theme.breakpoints.l}) {
      margin: 20px 30px 40px 0px;
      font-size: ${theme.scale(theme.fonts.m)};
    }
  }

  .Expertise__SliderPlus-Mobile {
    width: 26px;
    margin-left: 0px;
    position: absolute;
    bottom: 0;
  }

  .Expertise__SliderPlus-Desktop {
    width: 26px;
  }

  .slick-dots {
    list-style-type: none;
    position: relative;
    right: 70%;
    bottom: 0px;

    @media (min-width: ${theme.breakpoints.l}) {
      li {
        width: auto;
        height: 25%;
        padding: ${theme.scale('20px 30px')};
        margin: 0px;
        display: flex;
        align-items: center;
        border-left: 13px solid white;
        border-right: 13px solid white;

        &:last-of-type {
          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: calc(100% - 40px);
            height: 1px;
            background-color: ${theme.colors.primaryblue};
          }
        }

        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: calc(100% - 40px);
          height: 1px;
          background-color: ${theme.colors.primaryblue};
        }
      }
    }
  }

  .Expertise__Legend {
    display: none !important;

    @media (min-width: ${theme.breakpoints.l}) {
      display: flex !important;
      flex-direction: column;
      width: 400px;
      background-color: white;
      text-align: left;
      padding-left: 0;
      max-height: 800px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      height: 100%;
      justify-content: space-between;
      position: absolute;
      left: 0;

      .slick-active {
        @media (min-width: ${theme.breakpoints.l}) {
          border-left: 13px solid ${theme.colors.primaryblue};
        }
      }
    }
  }

  .slick-list {
    width: 100%;
    position: relative;
    left: 0;

    @media (min-width: ${theme.breakpoints.l}) {
      left: 400px;
      width: calc(100% - 400px);
    }
  }

  .Expertise__Slider {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }

  .Expertise__Slider-CustomPaging {
    font-family: ${theme.fontFamily.primary};
    font-weight: 600;
    margin-bottom: -8px;
    line-height: 1.2;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin: 0;
      padding: 0;
    }
  }
`;

export const ExpertiseSliderContentContainer = styled.div`
  padding-top: 35px;
  padding-right: 100px;
  border-top: 1px solid ${theme.colors.primaryblue};
  border-bottom: 1px solid ${theme.colors.primaryblue};
  height: 432px;
  position: relative;

  @media (min-width: ${theme.breakpoints.l}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 0px;
  }

  .Expertise__PlusSign {
    width: 100%;
    display: block;
    background-color: white;
    position: sticky;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: -60px;

    @media (min-width: ${theme.breakpoints.l}) {
      margin-bottom: 0;
    }
  }
`;

export const ExpertiseHeaderContainer = styled.div`
  padding: 20px 0 14px 0px;
  border-bottom: 1px solid ${theme.colors.primaryblue};
  border-top: 1px solid ${theme.colors.primaryblue};
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Expertise__SliderTitle-Mobile {
    margin: 0;
    font-family: ${theme.fontFamily.primary};
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > * {
      margin: 0;
      padding: 0;
    }

    @media (min-width: ${theme.breakpoints.s}) {
      font-size: 22px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      p:first-of-type {
        margin-right: 10px;
      }
    }

    @media (min-width: ${theme.breakpoints.l}) {
      padding: 20px;
      min-height: initial;
    }
  }

  svg {
    fill: ${theme.colors.primaryblue};
    height: 45px;
  }
`;
