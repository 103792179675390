import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../../components/Text/Text';
import CountUp from 'react-countup';
import { SingleGraphContainer } from './Graphics.css';

const SingleChart = ({ query, index, isVisible }) => {
  const graphRef = useRef(null);
  const [result, setResult] = useState(0);
  const [circleLength, SetCircleLength] = useState(0);
  const [counterVisible, setCounterVisible] = useState(false);

  useEffect(() => {
    if (graphRef.current) {
      const radius = 100;
      const circleLength = Math.PI * (radius * 2);
      const result = (query.number * circleLength) / 100;

      setResult(result);
      SetCircleLength(circleLength);
    }
  }, [graphRef.current]);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setCounterVisible(true);
      }, 1250);
    } else {
      setCounterVisible(false);
    }
  }, [isVisible]);

  return (
    <SingleGraphContainer
      result={result}
      circleLength={circleLength}
      isVisible={isVisible}
    >
      <div
        className={`Graphics__PieChart Graphics__PieChart-no${index + 1} ${
          query.value === 'Percentage' ? 'is-pie-chart' : ''
        }`}
      >
        {query.value === 'Percentage' ? (
          <svg ref={graphRef} className='pie-chart' id='pie-chart'>
            <circle className='pie-chart-value'></circle>
          </svg>
        ) : (
          ''
        )}
        <div className='Graphics__Pie-Text'>
          {counterVisible ? (
            <>
              <CountUp start={0} duration={3} end={query.number} />
              {query.value === 'Percentage' ? '%' : ''}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='Graphics__Description'>
        <Text
          as='div'
          customClassName='Graphics__Text'
          dangerouslySetInnerHTML={{ __html: query.body.html }}
        />
      </div>
    </SingleGraphContainer>
  );
};

SingleChart.propTypes = {
  query: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default SingleChart;
