import styled from "@emotion/styled";
import { theme } from "../../styles";

export const Hero = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: -1;
  padding-top: 60px;
  margin-bottom: 80px;

  @media (min-width: ${theme.breakpoints.s}) {
    padding-top: calc(
      ${theme.spacing.desktop.primary} - ${theme.headerHeight.desktop} + 40px
    );
  }

  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: ${theme.scale('200px', 0.4)};
  }
`;

export const HeroContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-top: 50px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 0px;
  }

  .Home__Title {
    font-weight: 500;
    font-family: ${theme.fontFamily.secondary};
    margin-bottom: 35px;
    line-height: 1;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    display: initial;

    > * {
      margin: 0;
      padding: 0;
    }

    @media (min-width: ${theme.breakpoints.s}) {
      font-size: ${theme.scale(theme.fonts.xxl_large)};
      letter-spacing: 0px;
    }

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: ${theme.scale(theme.fonts.xxxl)};
      margin-bottom: 20px;
    }

    @media (min-width: ${theme.breakpoints.l}) {
      font-size: ${theme.scale(theme.fonts.xxxxl, 0.3)};
    }
  }

  .Home_HeroText {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
      font-size: 53px;
    }

    > * {
      margin: 0;
      padding: 0;
    }
  }
`;

export const HeroBackgroundContainer = styled.div`
  position: relative;
  z-index: -1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: ${theme.scale('150px', 0.4)};
    overflow: inherit;
  }

  .Home__Backdrop-Parallax {
    position: absolute;
    z-index: -1;
    top: -125px;
    width: calc(100% - 40px);
    max-width: 340px;

    @media (min-width: ${theme.breakpoints.s}) {
      max-width: 400px;
      top: -200px;
    }
    
    @media (min-width: ${theme.breakpoints.md}) {
      width: 100vw;
      top: -400px;
      max-width: 1100px;
    }
  }

  .Home__Backdrop {
    width: 100%;
  }
`;
