import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CountryFlagsContainer } from './CountryFlags.css';
import { navigate } from 'gatsby';
import Fade from 'react-reveal/Fade';

const CountryFlags = ({ flags, onCountrySelect, i18n }) => {
  const [countryState, setCountryState] = useState('');

  useEffect(() => {
    if (countryState) {
      onCountrySelect(countryState);
    }
  }, [countryState]);

  const clickHandler = (e) => {
    e.preventDefault();
    setCountryState(e.target.dataset.country);
    if (i18n.locale === 'fr-ca') {
      navigate('/fr/projects#ProjectTilesAnchor');
    } else {
      navigate('/projects#ProjectTilesAnchor');
    }
  };

  return (
    <CountryFlagsContainer>
      {flags.map((flag) => {
        return (
          <Fade key={flag?.flag_image.url} bottom distance='15px'>
            <div className='CountryFlag__ImageContainer'>
              <img alt={flag?.country_name} src={flag?.flag_image?.url} />
              <a
                onClick={clickHandler}
                data-country={flag?.country}
                className='CountryFlag__Link'
              >
                {flag?.country}
              </a>
            </div>
          </Fade>
        );
      })}
    </CountryFlagsContainer>
  );
};

CountryFlags.propTypes = {
  flags: PropTypes.array.isRequired,
  i18n: PropTypes.object,
};

export default CountryFlags;
