import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Title from '../../Title/Title';
import Text from '../../Text/Text';
import {
  ExpertiseSectionContainer,
  ExpertiseSliderContentContainer,
  ExpertiseHeaderContainer,
} from './ExpertiseSection.css';
import Slider from 'react-slick';
import { LargeDesktopDown, LargeDesktopUp } from '../../Media/Media';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Plus } from '../../Icons/Plus';
import { Finger } from '../../Icons/Finger';
import LocalizedLink from '../../LocalizedLink';
import Fade from 'react-reveal/Fade';
import ProjectsContext from '../../../context/ProjectsProvider';

const ExpertiseSection = ({ query }) => {
  const projectsContext = useContext(ProjectsContext);
  const settings = {
    customPaging: function(i) {
      return (
        <a>
          <Fade delay={i * 150} right distance='15px'>
            <LargeDesktopUp>
              {query ? (
                <div
                  role='button'
                  onClick={() =>
                    projectsContext.setSelectedService(
                      query[i].service_title.text.trim()
                    )
                  }
                >
                  <Title
                    type='h3'
                    as='h3'
                    customClassName='Expertise__Slider-CustomPaging'
                    dangerouslySetInnerHTML={{
                      __html: query[i].service_title.html,
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </LargeDesktopUp>
          </Fade>
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb Expertise__Legend',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          autoplay: true,
          autoplaySpeed: 8000,
          pauseOnFocus: true,
          pauseOnHover: true,
        },
      },
    ],
  };

  return (
    <Fade bottom distance='30px'>
      <ExpertiseSectionContainer>
        {query ? (
          <Slider className='Expertise__Slider' {...settings}>
            {query.map((service, index) => {
              return (
                <div key={`Expertise__slider-${index}`}>
                  <LargeDesktopUp>
                    <ExpertiseSliderContentContainer>
                      {service?.service_body ? (
                        <Text
                          as='div'
                          type='secondary'
                          customClassName='Expertise__SliderContent'
                          dangerouslySetInnerHTML={{
                            __html: service?.service_body?.html,
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {service?.service_link?.uid ? (
                        <LocalizedLink
                          to={`/${service.service_link.uid}`}
                          className='Expertise__PlusSign'
                        >
                          <Plus className='Expertise__SliderPlus-Desktop PlusIcon' />
                        </LocalizedLink>
                      ) : service?.service_link?.url &&
                        !service?.service_link?.uid ? (
                        <a
                          href={service.service_link.url}
                          target='_blank'
                          className='Expertise__PlusSign'
                        >
                          <Plus className='Expertise__SliderPlus-Desktop PlusIcon' />
                        </a>
                      ) : (
                        ''
                      )}
                    </ExpertiseSliderContentContainer>
                  </LargeDesktopUp>
                  <LargeDesktopDown>
                    {service?.service_title ? (
                      <ExpertiseHeaderContainer>
                        <Title
                          as='h3'
                          type='h4'
                          customClassName='Expertise__SliderTitle-Mobile'
                          dangerouslySetInnerHTML={{
                            __html: service?.service_title?.html,
                          }}
                        />
                        <Finger />
                      </ExpertiseHeaderContainer>
                    ) : (
                      ''
                    )}
                    {service?.service_body ? (
                      <Text
                        type='secondary'
                        customClassName='Expertise__SliderText-Mobile'
                        dangerouslySetInnerHTML={{
                          __html: service?.service_body?.html,
                        }}
                      />
                    ) : (
                      ''
                    )}
                    {service.service_link.uid ? (
                      <LocalizedLink to={`/${service.service_link.uid}`}>
                        <Plus className='Expertise__SliderPlus-Mobile PlusIcon' />
                      </LocalizedLink>
                    ) : service?.service_link?.url &&
                      !service?.service_link?.uid ? (
                      <a href={service.service_link.url} target='_blank'>
                        <Plus className='Expertise__SliderPlus-Mobile PlusIcon' />
                      </a>
                    ) : (
                      ''
                    )}
                  </LargeDesktopDown>
                </div>
              );
            })}
          </Slider>
        ) : (
          ''
        )}
      </ExpertiseSectionContainer>
    </Fade>
  );
};

export default ExpertiseSection;

ExpertiseSection.propTypes = {
  query: PropTypes.array.isRequired,
};
