import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSectionContainer } from './ArticleSection.css';
import ProjectTiles from '../../ProjectTiles/ProjectTiles';
import HeroArticle from './HeroArticle';
import LocaleContext from '../../../context/LocaleProvider';
import moment from 'moment';

const ArticleSection = ({ query }) => {
  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  query = query.sort((a, b) => {
    if (moment(a.node.data.date) > moment(b.node.data.date)) {
      return -1;
    } else {
      return 1;
    }
  });

  const homePageHighlightedTile = query.filter(
    (project) => project.node.data.highlight_on_homepage
  );

  let TopPriorityArticle = query.find(({ node: article }) =>
    article.data.top_priority
      ? article.data.top_priority
      : console.log('nothing to display on top Priority Article')
  );

  let finalQueryTiles = homePageHighlightedTile.slice(0, 4);

  if (TopPriorityArticle) {
    finalQueryTiles = homePageHighlightedTile
      .filter((article) => article.node.uid !== TopPriorityArticle.node.uid)
      .slice(0, 3);
  } else {
    TopPriorityArticle = finalQueryTiles.shift();
  }

  if (!homePageHighlightedTile) {
    finalQueryTiles = query;
  }

  return (
    <ArticleSectionContainer>
      <HeroArticle query={TopPriorityArticle} />
      <ProjectTiles
        className='Articles__ProjectTiles'
        query={finalQueryTiles}
        id='ARTICLES'
      />
    </ArticleSectionContainer>
  );
};

export default ArticleSection;
