import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from '../../context/LocaleProvider';
import SEO from '../SEO';
import {
  Hero,
  HeroContentContainer,
  HeroBackgroundContainer,
} from '../PagesLayout/index.css';
import { SecondaryRedCircle } from '../Icons/SecondaryRedCircle';
import Img from 'gatsby-image';
import { Desktop, Mobile } from '../Media/Media';
import Title from '../Title/Title';
import { getQuery } from '../../functions/getQuery';
import ExpertiseSection from '../PageSections/ExpertiseSection/ExpertiseSection';
import HeadingContainer from '../PageSections/HeadingContainer/HeadingContainer';
import ArticleSection from '../PageSections/ArticleSection/ArticleSection';
import { Parallax } from 'react-scroll-parallax';
import { TopSpacing } from '../TopSpacing/TopSpacing.css';
import ProjectTiles from '../ProjectTiles/ProjectTiles';
import Graphics from '../Graphics/Graphics';
import CountryFlags from '../CountryFlags/CountryFlags';
import ProjectsContext from '../../context/ProjectsProvider';
import Transition from '../Transition/Transition';
import BigCircleTransition from '../Transition/BigCircleTransition';
import { useTextAnimationSecondary } from '../../hooks/useTextAnimation';
import Fade from 'react-reveal/Fade';
import Footer from '../Footer/Footer';

const IndexLayout = ({ data, location }) => {
  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];
  const titleRef = useRef(null);
  const projectsContext = useContext(ProjectsContext);
  useTextAnimationSecondary({ element: titleRef, lineContinuationPx: 30 });
  const projectsSectionJson = getQuery(
    ['allPrismicProjectSection', 'edges'],
    data
  );
  const projectsJsonContent = getQuery(['projectsContent', 'data'], data);
  const articlesJsonContent = getQuery(
    ['allPrismicArticleIndividual', 'edges'],
    data
  );
  const homepage = getQuery(['homepage', 'data'], data);
  const expertiseJsonContent = getQuery(
    ['homepage', 'data', 'expertise', 'document', 0, 'data'],
    data
  );
  const projectSectionJsonContent = getQuery(['projects', 'data'], data);
  const articleSectionJsonContent = getQuery(['articles', 'data'], data);
  let homePageHighlightedTile = projectsSectionJson.filter(
    (project) => project.node.data.highlight_on_homepage
  );

  if (projectsContext.selectedExpertise) {
    const formattedExpertise = projectsContext.selectedExpertise
      .split('  ')
      .join(' ');
    const filteredByExpertise = projectsSectionJson.filter((project) => {
      let industry = project.node.data.industry_selector_eng;

      if (i18n.locale == 'fr-ca') {
        industry = project.node.data.industry_selector_fr;
      }

      return industry === formattedExpertise;
    });

    if (filteredByExpertise.length > 0) {
      homePageHighlightedTile = filteredByExpertise.slice(0, 3);
    }
  }

  return (
    <>
      <SEO
        location={location}
        pathname={location.pathname}
        locale={i18n.locale}
      />
      <Transition>
        <TopSpacing>
          <Hero style={{ marginTop: '-100px' }}>
            <HeroContentContainer>
              {homepage?.title ? (
                <Title
                  as='h1'
                  type='h1'
                  ref={titleRef}
                  customClassName='Home__Title'
                  dangerouslySetInnerHTML={{ __html: homepage.title.html }}
                />
              ) : (
                ''
              )}
              {homepage?.hero_body ? (
                <Fade distance='30px' bottom delay={500}>
                  <Title
                    as='h2'
                    type='h2_secondary'
                    customClassName='Home_HeroText'
                    dangerouslySetInnerHTML={{
                      __html: homepage.hero_body.html,
                    }}
                  />
                </Fade>
              ) : (
                ''
              )}
            </HeroContentContainer>
            <HeroBackgroundContainer>
              <Parallax
                className='Home__Backdrop-Parallax'
                y={[-15, 35]}
                tagOuter='figure'
              >
                <BigCircleTransition>
                  <SecondaryRedCircle className='Home__Backdrop' />
                </BigCircleTransition>
              </Parallax>
              <Desktop>
                {homepage?.hero_image?.desktop?.childImageSharp ? (
                  <Img
                    fluid={
                      homepage?.hero_image?.desktop?.childImageSharp?.fluid
                    }
                    style={{ width: '100%' }}
                  />
                ) : (
                  ''
                )}
              </Desktop>
              <Mobile>
                {homepage?.hero_image?.mobile?.childImageSharp ? (
                  <Img
                    fluid={homepage.hero_image.mobile.childImageSharp.fluid}
                    style={{ width: '100%' }}
                  />
                ) : (
                  ''
                )}
              </Mobile>
            </HeroBackgroundContainer>
          </Hero>
          {expertiseJsonContent?.title && expertiseJsonContent?.body ? (
            <HeadingContainer
              title={expertiseJsonContent.title.text}
              body={expertiseJsonContent.body.html}
              isHTML
            />
          ) : (
            ''
          )}
          {expertiseJsonContent?.service_block ? (
            <ExpertiseSection query={expertiseJsonContent.service_block} />
          ) : (
            ''
          )}
          {projectsJsonContent ? (
            <>
              <Graphics />
              <CountryFlags
                i18n={i18n}
                onCountrySelect={(countryState) =>
                  projectsContext.setSelectedCountry(countryState)
                }
                flags={projectsJsonContent.flags_selector}
              />
            </>
          ) : (
            ''
          )}
          {projectSectionJsonContent?.project_page_title &&
          projectSectionJsonContent?.project_page_body ? (
            <>
              <HeadingContainer
                title={projectSectionJsonContent.project_page_title.text}
                body={projectSectionJsonContent.project_page_body.text}
              />
              <ProjectTiles
                className='ProjectTiles'
                query={homePageHighlightedTile}
              />
            </>
          ) : (
            ''
          )}
          {articleSectionJsonContent?.title ? (
            <Fade distance='30px' bottom>
              <HeadingContainer
                title={articleSectionJsonContent.title.text}
                style={{ paddingBottom: '0px', marginBottom: '0px' }}
              />
            </Fade>
          ) : (
            ''
          )}
          {articlesJsonContent ? (
            <Fade distance='30px' bottom delay={500}>
              <ArticleSection query={articlesJsonContent} />
            </Fade>
          ) : (
            ''
          )}
          <Footer />
        </TopSpacing>
      </Transition>
    </>
  );
};

IndexLayout.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexLayout;
