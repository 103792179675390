import React from 'react';

export const Finger = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 383 382.7'
      style={{ enableBackground: 'new 0 0 383 382.7' }}
      xmlSpace='preserve'
    >
      <path
        d='M247.1,382.7c-18.9,0-37.6,0-56,0c-8.8,0-16.8-6.2-19.9-15.3l-0.4-1.2c-2.3-7.1-4.8-14.3-7.3-21.5c-2.8-8-7.4-15.4-14-22.8
	c-1.5-1.7-3.1-3.5-4.7-5.2c-6.3-6.9-12.8-13.9-17.9-22.7c-5.7-9.8-10.4-18.6-14.4-27.1c-3-6.3-5.7-12.8-8.2-19.1
	c-4.3-10.5-8.3-20.4-14-29.4c-1.4-2.2-2.8-4.4-4.2-6.6c-1.3-2.1-2.7-4.2-4-6.3c-4.6-7.3-3.9-15,1.9-21.2c7.5-8.2,17.6-12.5,29.9-13
	c14-0.5,26.7,4.8,38.6,16.4c1.5,1.4,2.9,2.9,4.3,4.3c0.3-6.1,0.6-12.2,0.9-18.3c0.5-10.6,1-21.2,1.6-31.9c0.3-6.7,0.7-13.5,1.1-20.2
	c0.3-6.6,0.7-13.1,1-19.7c0.2-4.2,0.4-8.5,0.6-12.7c0.2-4.6,0.4-9.2,0.6-13.8c0.2-4.4,0.4-8.7,0.6-13.1c0.5-11.7,1.1-23.9,2.2-35.9
	C166.7,11.2,178,0.5,193.5,0c16.5-0.6,29.2,11.3,30,28.1c0.6,12.3,1.4,24.9,2,37l0.6,10.9c0.4,6.5,0.7,12.9,1.1,19.4
	c0.4,7,0.8,14,1.2,21c2.3-1.1,5-2,8.2-2.5c12.7-2.2,21.3,2.6,26.3,7c3.1,2.8,5.4,5.9,7.1,8.9c1.8-0.9,3.9-1.6,6-2
	c16.2-3.3,24.9,4,29.4,10.7c2.7,4,4.2,6.6,5.3,9.5c2.7-0.8,5.6-1,8.5-0.7c7,0.8,13.9,4.9,18.3,11c4.3,5.8,6.5,12.8,6.5,20.9
	c0,6,0.1,12,0.1,18c0.1,11.4,0.2,23.3,0.1,34.9c0,9.7-0.2,19.5-0.4,29.1c-0.1,5.9-0.2,11.8-0.3,17.7c-0.2,16.5-6,31-11.6,43
	c-3.4,7.3-5.5,15.3-6.2,23.9c-0.5,5.7-1.1,11.6-2,18.2c-1.3,9.9-11.7,18.8-21.9,18.8C283.6,382.7,265.3,382.7,247.1,382.7z
	 M98.8,196.4c1.1,1.8,2.2,3.5,3.3,5.2c1.4,2.2,2.9,4.5,4.3,6.7c6.6,10.5,11.1,21.6,15.5,32.4c2.6,6.3,5,12.2,7.8,18
	c3.8,8,8.3,16.4,13.7,25.8c4,6.9,9.5,12.9,15.4,19.3c1.7,1.8,3.3,3.6,4.9,5.4c8.3,9.3,14.1,18.8,17.7,29.1c2.6,7.2,5,14.6,7.4,21.8
	l0.4,1.2c0.5,1.6,1.5,2.3,1.9,2.3c36.2,0,73.5,0,110.8,0c0.9-0.1,2.7-1.6,3.1-2.5c0.8-6.2,1.4-11.9,1.9-17.2c0.9-10.8,3.6-21,8-30.4
	c4.8-10.1,9.7-22.2,9.8-35.2c0.1-5.9,0.2-11.9,0.3-17.8c0.2-9.5,0.4-19.2,0.4-28.8c0.1-11.5,0-23.3-0.1-34.7c0-6-0.1-12-0.1-18
	c0-4-0.9-7.2-2.8-9.7c-1.3-1.8-3.4-3.1-5.2-3.4c-1-0.1-1.5,0.2-1.9,0.5c-0.4,0.4-1.2,1.8-1.7,2.7c-0.5,0.9-1,1.9-1.7,2.9
	c-2.2,3.4-6.4,5-10.3,4c-3.9-1-6.8-4.4-7.1-8.5l-0.1-1.6c-0.5-5.9-0.7-9.5-1.3-11.4c-0.3-1.3-1-2.4-3.3-5.8c-1.4-2.1-3.3-4-9.8-2.6
	c-1.9,0.4-3,1.1-3.6,3.8c-0.3,1.4-0.6,2.8-0.9,4.3c-0.1,0.7-0.3,1.5-0.4,2.2c-0.9,4.4-4.8,7.6-9.3,7.6c-0.1,0-0.1,0-0.2,0
	c-4.6-0.1-8.5-3.4-9.2-8c-0.4-2.7-0.5-5.2-0.5-7.5c0-1.8,0-3.5-0.3-4.5c-1-4.2-4.6-13.7-15.6-11.8c-4.6,0.8-5.1,2.3-5.3,2.9
	c-1.3,3.5-2.2,7.4-3.1,11.2c-0.4,1.8-0.8,3.5-1.3,5.3c-1.1,4.4-5.1,7.3-9.6,7.1c-4.5-0.2-8.2-3.5-8.9-8l-0.1-0.4
	c-0.2-0.9-0.5-2.4-0.6-4.1c-0.3-4.5-0.5-9.1-0.7-13.6c-0.2-4-0.4-7.9-0.6-11.9c-0.5-8.2-0.9-16.4-1.4-24.6
	c-0.4-6.5-0.8-12.9-1.1-19.4l-0.6-10.9c-0.7-12.1-1.4-24.7-2.1-37.1c-0.3-5.1-3.1-10.4-10.4-10.1c-7.5,0.2-9.5,5.2-9.9,9.3
	c-1,11.6-1.6,23.5-2.1,35c-0.2,4.4-0.4,8.8-0.6,13.2c-0.2,4.5-0.4,9.1-0.6,13.6c-0.2,4.3-0.4,8.5-0.6,12.8c-0.3,6.6-0.7,13.2-1,19.8
	c-0.4,6.7-0.7,13.4-1.1,20.1c-0.5,10.6-1.1,21.2-1.6,31.8c-0.4,8.6-0.8,17.2-1.3,25.8c-0.2,3.2-0.4,6.5-0.6,9.7
	c-0.1,1.5-0.2,2.9-0.3,4.4c-0.2,3.2-2,6.2-4.9,7.8l-1,0.6c-4.6,2.5-10.3,0.9-12.9-3.7c-0.1-0.2-0.2-0.4-0.3-0.6
	c-1.4-1.5-2.8-3.1-4.2-4.6c-4-4.5-7.9-8.7-12-12.6c-8.1-7.9-15.9-11.4-24.6-11.1C107.8,190.6,102.8,192.5,98.8,196.4z M165,214
	L165,214L165,214z M97.8,197.5L97.8,197.5L97.8,197.5z'
      />
      <path
        d='M48.2,29.9c2.6,0.2,4.9,0.5,7.2,0.5c32.5,0,34.9,0,67.4,0c8.2,0,12.3,6.3,8.6,12.7c-2.2,3.8-5.8,4.3-9.8,4.3
	c-29.6,0-29.3,0-58.9,0c-4.8,0-9.5,0-14.5,0c0,3.4,0,7.2,0,11C48,66.3,40.4,70.8,34,66.5c-10.3-6.9-20.5-14-30.5-21.3
	c-4.9-3.5-4.6-9.9,0.3-13.4c9.8-7,19.7-13.9,29.7-20.6c6.9-4.7,14.2-0.9,14.6,7.5C48.3,22.2,48.2,25.7,48.2,29.9z'
      />
      <path
        d='M335.2,30.4c0-4.3,0-7.8,0-11.2c0-4.2,1.8-8.2,5.5-9.2c3-0.8,7.3,0.1,10.1,1.9c8.6,5.5,16.8,11.7,25.2,17.6
	c0.9,0.7,1.9,1.3,2.8,2c5.7,4.6,5.9,10.1,0,14.4c-9.6,7.1-19.3,13.9-29.2,20.5c-6.8,4.6-14.2,0.3-14.4-8.1c-0.1-3.6,0-7.3,0-10.8
	c-9,0-17.7,0-26.4,0c-26,0-21.9,0-47.9,0c-8.3,0-12.9-5.8-9.7-12.3c1.8-3.7,5.3-4.8,9.4-4.8c31.3,0.1,32.6,0.1,63.9,0.1
	C327.9,30.4,331.3,30.4,335.2,30.4z'
      />
    </svg>
  );
};
