import styled from '@emotion/styled';
import { theme } from '../../styles';

export const GraphicsContainerWithTitle = styled.div`
  margin-bottom: 60px;

  .Graphics__Title {
    margin-bottom: 20px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: 40px;
    }
  }
`;

export const GraphicsContainer = styled.div`
  .Graphics__Text {
    font-family: ${theme.fontFamily.secondary};
    text-transform: uppercase;
    font-weight: 500;
    font-size: ${theme.fonts.m_small};
    margin: 30px auto 0;

    @media (min-width: ${theme.breakpoints.l}) {
      font-size: ${theme.fonts.m};
    }

    @media (min-width: ${theme.breakpoints.l}) {
      margin-top: 60px;
    }
  }

  .Graphics__Description {
    display: flex;
    height: 100%;
    text-align: center;

    .Graphics__Text {
      text-align: center;
      margin-bottom: 0;
      font-family: ${theme.fontFamily.tertiary};

      > * {
        margin: 0;
      }
    }
  }

  .Graphics__Pie-Text {
    position: absolute;
    font-family: ${theme.fontFamily.secondary};
    font-size: ${theme.fonts.xxl_medium};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: bold;

    @media (min-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fonts.xxl_large};
    }

    @media (min-width: ${theme.breakpoints.l}) {
      font-size: ${theme.scale(theme.fonts.xxxl_small)};
    }
  }

  .Graphics__PieChart {
    position: relative;
    color: black;
    width: 100%;
    position: relative;
    padding-bottom: 100%;
    border-radius: 50%;

    &.is-pie-chart {
      .Graphics__Pie-Text {
        font-size: 40px;
        margin-top: 30px;
      }
    }
  }

  .Graphics__PieChart-no1 {
    color: ${theme.graphicsColors.lightGreen};
    background-color: ${theme.graphicsColors.darkGreen};

    .pie-chart-value {
      stroke: ${theme.graphicsColors.darkGreen};
    }
  }

  .Graphics__PieChart-no2 {
    color: ${theme.graphicsColors.lavanderPurple};
    background-color: ${theme.graphicsColors.lavanderPurple};

    .pie-chart-value {
      stroke: ${theme.graphicsColors.darkBlue};
    }

    .Graphics__Pie_Text-no2 {
      position: absolute;
      top: 20%;
      left: 20%;
      font-size: ${theme.scale(theme.fonts.xxxl_small)};
      font-family: ${theme.fontFamily.secondary};
    }
  }

  .Graphics__PieChart-no3 {
    background-color: ${theme.graphicsColors.red};
    color: ${theme.graphicsColors.lightRose};

    .pie-chart-value {
      stroke: ${theme.graphicsColors.red};
    }

    .Graphics__Pie_Text-no3 {
      position: absolute;
      top: 20%;
      left: 20%;
      font-size: ${theme.scale(theme.fonts.xxxl_small)};
      font-family: ${theme.fontFamily.secondary};
    }
  }

  .Graphics__PieChart-no4 {
    background-color: ${theme.graphicsColors.lightOrange};
    color: ${theme.graphicsColors.darkOrange};

    .pie-chart-value {
      stroke: ${theme.graphicsColors.darkOrange};
    }

    .Graphics__Pie_Text-no4 {
      position: absolute;
      top: 20%;
      left: 20%;
      font-size: ${theme.scale(theme.fonts.xxxl_small)};
      font-family: ${theme.fontFamily.secondary};
    }
  }
`;

export const SingleGraphContainer = styled.div`
  width: 100%;

  .pie-chart {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 7.5px;
    transform: translate(-50%, -50%) rotate(265deg);
    top: 50%;
    left: 50%;
    border-radius: 50%;

    .pie-chart-value {
      fill: none;
      stroke-width: 148%;
      transition: stroke-dasharray 2s ease;
      r: 100px;
      cx: 50%;
      cy: 50%;
      stroke-dasharray: ${(props) =>
        props.isVisible
          ? (props) => props.result + ', ' + props.circleLength
          : 0 + ', ' + props.circleLength};

      @media (min-width: ${theme.breakpoints.s}) {
        stroke-width: 108%;
      }
    }
  }
`;
